import Parseable from '@/misc/Parseable';
import Task from '@/models/Task';
import Image from '@/models/Image';

export default class SessionACTT extends Parseable {
    public static parseFromObject(object: Partial<SessionACTT>): SessionACTT {
        const sessionACTT = new SessionACTT();
        Object.assign(sessionACTT, object);
        return sessionACTT;
    }

    public id!: string;
    public actt!: string;

    public comment?: string;
    public done!: boolean;
    public task?: string | Task;
    public images!: Image[];
    public workSession!: string;
    public createdAt!: string;

    public parseToObject(): Partial<SessionACTT> {
        const tmp: any = {...this};
        return tmp;
    }
}
