import Parseable from '@/misc/Parseable';
import User from '@/models/User';
import Area from '@/models/Area';
import CleanTime from '@/models/CleanTime';
import Task from '@/models/Task';
import SessionACTT from '@/models/SessionACTT';

export default class ACTT extends Parseable {
    public static parseFromObject(object: Partial<ACTT>): ACTT {
        const actt = new ACTT();
        object.users = User.parseFromArray(object.users!) as User[];
        object.area = Area.parseFromObject(object.area as Partial<Area>);
        object.cleanTime = CleanTime.parseFromObject(object.cleanTime as Partial<CleanTime>);
        object.task = Task.parseFromObject(object.task as Partial<Task>);

        if (object.sessionACTT) {
            object.sessionACTT = SessionACTT.parseFromObject(object.sessionACTT as Partial<SessionACTT>);
        }
        Object.assign(actt, object);

        return actt;
    }

    public id!: string;
    public origin!: string;

    public users: User[] | string[] = [];
    public area?: string | Area;
    public cleanTime?: string | CleanTime;
    public task?: string | Task;
    public sessionACTT?: SessionACTT | null;

    // RN-393 just a swallow copy ... maybe this is not enough...
    public parseToObject(): Partial<ACTT> {
        const tmp: any = {...this};
        tmp.users = tmp.users.slice();
        tmp.area = {...tmp.area};
        tmp.cleanTime = {...tmp.cleanTime};
        tmp.task = {...tmp.task};
        tmp.sessionACTT = {...tmp.sessionACTT};
        return tmp;
    }
}
