








































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ACTT from '@/models/ACTT';
import JobRepository from '@/api/repositories/JobRepository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import WorkSession from '@/models/WorkSession';
import User from '@/models/User';
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import Image from '@/models/Image';

const jobRepository: JobRepository = RepositoryFactory.get('job');

@Component({
    components: {
        Hooper,
        Slide,
        HooperNavigation,
        UserInitialsComponent: () => import(
            /* webpackChunkName: "UserInitialsComponent" */
            '@/components/user/UserInitials.component.vue'),
    },
})
export default class SessionACTTDetailsComponent extends Vue {

    @Prop({default: () => new ACTT()})
    public actt!: ACTT;

    @Prop({default: () => new WorkSession()})
    public workSession!: WorkSession;

    public imagesArray: any[] = [];

    public imageDialog: boolean = false;
    public selectedImage: any = null;
    public imageSize: number = 80;
    public maxImagesToShow: number = 6;

    // for faster Image loading in the dialog
    public selectedImageKey: number = 0;
    public imageCache: Image[] = [];

    public initialsConfig = {
        statusBadgeData: {
            icon: 'mdi-close',
            backgroundColor: 'error',
        },
    };

    @Watch('actt', {immediate: true})
    public acttChanged() {
        if (this.actt.sessionACTT!.images) {
            this.imagesArray = this.actt.sessionACTT!.images.map((image) => {
                return {loaded: false, url: ''};
            });
            this.actt.sessionACTT!.images.forEach(async (image, index) => {
                const apiImage = await jobRepository.getSessionACTTImage(this.actt.sessionACTT!.workSession!,
                    this.actt.id, image.id, true);
                const fileObject = URL.createObjectURL(apiImage);

                this.imagesArray[index] = {
                    id: image.id,
                    loaded: true,
                    url: fileObject,
                };
                this.$forceUpdate();
            });
        }
    }

    public getCommentData() {
        return {
            username: (this.workSession.user as User).fullName,
            date: this.$options.filters!.toDate(this.actt.sessionACTT!.createdAt),
            time: this.$options.filters!.toZoneTime(this.actt.sessionACTT!.createdAt),
        };
    }

    private changeImageButtonClick(isRight: boolean) {

      isRight ? this.selectedImageKey++ : this.selectedImageKey--;
      if (this.selectedImageKey in this.imageCache) {
        this.selectedImage = this.imageCache[this.selectedImageKey];
      } else {
        this.openSelectedImage(this.imagesArray[ this.selectedImageKey]);
      }
    }

    get itemsToShow() {
      return this.actt.sessionACTT!.images.length >= this.maxImagesToShow ? this.maxImagesToShow : this.actt.sessionACTT!.images.length;
    }

    public async openSelectedImage(image: any) {
        const apiImage = await jobRepository.getSessionACTTImage(this.actt.sessionACTT!.workSession!,
            this.actt.id, image.id, false);
        const fileObject = URL.createObjectURL(apiImage.blob);

        this.selectedImage = {
            id: image.id,
            loaded: true,
            url: fileObject,

        };
        this.imageCache[this.selectedImageKey] = this.selectedImage;
        this.imageDialog = true;
    }
}
